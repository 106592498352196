@import url(sanitize.css);
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*,
::before,
::after {
  margin: 0;
  padding: 0;

  scroll-behavior: smooth;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}
