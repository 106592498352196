.login {
  .login__grid {
    /** Mobile Responsive */
    @media (max-width: 768px) {
      display: block;
    }

    display: grid;
    grid-template-columns: 45% 55%;
    width: 100%;

    .login__form {
      /** Mobile Responsive */
      @media (max-width: 768px) {
        padding: 10px 0;
        gap: 20px;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 50px;

      .logo {
        /** Mobile Responsive */
        @media (max-width: 768px) {
          display: block;
          padding: 20px;
          text-align: center;
          img {
            width: 50%;
          }
        }

        display: none;
      }

      .welcome-tag {
        width: 250px;
        height: 30px;
        background-color: #d9e5e5;
        border-radius: 30px;
        font-size: 14px;
        display: grid;
        grid-template-columns: 50% 50%;
        overflow: hidden;

        .welcome {
          color: #fff;
          background-color: #005153;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
        }

        .member-portal {
          color: #005153;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .description {
        height: fit-content;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 300px;
      }

      .form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 350px;

        .input-group {
          label {
            font-size: 12px;
            font-weight: bold;
            margin-left: 15px;
          }
        }

        .login-input {
          border-radius: 30px;
          border: 1px solid #e2e4ef;
          padding: 0;
          overflow: hidden;

          input {
            padding: 12px;
          }
        }

        .login-btn {
          button {
            width: 100%;
            color: #fff;
            padding: 12px;
            border-radius: 30px;
            background-color: #005153;
          }
        }

        .be-a-member-btn {
          button {
            width: 100%;
            color: #fff;
            padding: 12px;
            border-radius: 30px;
            background-color: #4b4585;
          }
        }

        .back-btn {
          button {
            width: 100%;
            border-radius: 30px;
          }
        }
      }

      .outro {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .sign-up-link {
          color: #3a56a9;
          font-weight: bold;
          text-decoration: none;
        }

        .forgot-password {
          color: #3a56a9;
          text-decoration: none;
        }
      }
    }

    .login__image {
      /** Mobile Responsive */
      @media (max-width: 768px) {
        display: none;
      }

      position: relative;

      > img {
        object-fit: cover;
        width: 100%;
        height: 100vh;
        background: no-repeat;
        opacity: 0.4;
      }

      .banner {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        > img {
          width: 300px;
        }

        p {
          width: 300px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
}
