.container {
  min-height: 100vh;
  background-color: #fdf4e6;
}

.be-a-member {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  .header {
    margin-bottom: 20px;
  }

  .invitation {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .input {
      border-radius: 30px;
      border: 1px solid #e2e4ef;
      overflow: hidden;

      input {
        padding: 12px;
      }
    }

    .submit {
      button {
        width: 100%;
        color: #fff;
        padding: 12px;
        border-radius: 30px;
        background-color: #4b4585;
      }
    }
  }

  .step-1 {
    .media-container {
      background-color: #000;
      height: 500px;
      border-radius: 20px;
      border: 3px solid black;
      overflow: hidden;

      video {
        width: 100%;
        height: 100%;
      }
    }
  }

  .step-2 {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .item {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
    }
  }

  .step-3 {
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
  }

  .actions {
    .done-watching {
      color: #696969;
      font-size: 14px;
      margin-bottom: 5px;
    }

    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .next {
      button {
        padding: 10px 30px;
        font-size: 20px;
        color: #fff;
        background-color: #4b4585;
      }
    }

    .submit {
      button {
        padding: 10px 30px;
        font-size: 20px;
        color: #fff;
        background-color: #005153;
      }
    }
  }
}
