.cs-select {
  position: relative;
  border-radius: 5px;
  padding-right: 5px;
  border: 1px solid #d3d8dd;

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      cursor: pointer;
      border: none;
      padding: 10px 0px;
      padding-left: 5px;
      padding-right: 10px;
      background-color: transparent;
      flex-grow: 1;
      text-align: left;
    }
  }

  .options {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    background-color: #fff;
    border: 1px solid #d3d8dd;
    padding: 5px;
    padding-bottom: 0px;

    li {
      cursor: pointer;
      padding: 5px 8px;
      text-align: left;
      margin-bottom: 5px;
      // white-space: nowrap;
      border-radius: 5px;

      &:hover,
      &.selected {
        // width: fit-content;
        color: #fff;
        background-color: #0ea5ef;
      }
    }

    &.show {
      display: block;
    }
  }

  &:hover {
    box-shadow: 0px 1px 3px #bdbdbd;
  }
}
