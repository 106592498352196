.loan-application {
  margin: 0 auto;
  max-width: 800px;
}

.loan-application__section {
  border-radius: 10px;
  border: 1px solid #d3d8dd;
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;

  &.section-1 {
    display: flex;
    justify-content: center;

    div {
      min-width: 250px;
      max-width: 400px;
    }
  }

  &.section-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .instructions {
      max-width: 450px;
      text-align: left;

      ul {
        margin-top: 10px;
        font-size: 14px;
        list-style-type: none;
      }
    }

    .col {
      display: flex;
      align-items: left;
      justify-content: center;
      gap: 10px;

      p {
        font-style: italic;
        font-size: 11px;
      }

      .download-files {
        display: flex;
        gap: 5px;
      }
    }

    .selected-files {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2px;

      .item {
        text-align: start;
        background-color: #d3d8dd;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .submit-btn {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  &.section-3 {
    text-align: left;

    p {
      margin-bottom: 20px;
    }

    .group {
      display: flex;
      gap: 20px;

      .button {
        height: 30px;
        width: 150px;
      }
    }
  }
}
