.loan-details-calc {
  margin: 0 auto;
  max-width: 800px;
}

.loan-details-calc__section {
  border-radius: 10px;
  border: 1px solid #d3d8dd;
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;

  &.section-1 {
    .title {
      font-size: 16px;
    }

    .desc {
      margin-top: 10px;
      font-size: 16px;
    }
  }

  &.section-grid {
    height: fit-content;

    .title {
      color: #005153;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .desc {
      margin-top: 10px;
      font-size: 16px;
      text-align: start;
    }

    .input {
      margin-top: 20px;
      text-align: start;

      label {
        font-size: 14px;
      }

      p {
        font-size: 11px;
        font-style: italic;
      }
    }
  }
}

.loan-details-calc__grid {
  /** Mobile */
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  display: grid;
  grid-template-columns: 45% 6% 45%;
  gap: 15px;
  justify-content: space-between;

  .loan-details-calc__grid--item {
    border-radius: 10px;
    border: 1px solid #d3d8dd;
    background-color: #ffffff;
    padding: 20px;

    .title {
      font-weight: bold;
      font-size: 18px;
    }

    .date {
      font-weight: 300;
      font-size: 13px;
    }

    .amount {
      color: #005153;
      font-size: 30px;
      font-weight: bold;
      margin-top: 20px;
    }
  }
}

.mediator {
  /** Mobile */
  @media (max-width: 768px) {
    display: none;
  }

  padding-top: 50px;
  text-align: center;
}
