.file-preview {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 300px;
  border-radius: 5px;
  margin-top: 5px;
  background-color: black;
  overflow: hidden;
}

.member-forum {
  margin: 0 auto;
  max-width: 600px;

  .member-forum__list {
    .member-forum__item {
      border-radius: 10px;
      border: 1px solid #d3d8dd;
      background-color: #ffffff;
      padding: 20px;
      margin-bottom: 20px;

      .media {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 300px;
        border-radius: 5px;
        margin-top: 5px;
        background-color: black;
        overflow: hidden;
      }

      .name {
        font-size: 14px;
        font-weight: bold;
      }

      .date {
        font-size: 11px;
        font-weight: bold;
        opacity: 0.6;
      }

      .title {
        font-size: 18px;
        margin-top: 20px;
        font-weight: bold;
      }

      .details {
        margin-top: 10px;
        font-size: 14px;
        opacity: 0.8;
      }
    }
  }
}
