.cs-input {
  border-radius: 5px;
  border: 1px solid #d3d8dd;
  transition: box-shadow 0.1s;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    padding: 10px;
    border: none;
    width: 100%;
    outline: none;
  }

  &:hover {
    box-shadow: 0px 1px 3px #bdbdbd;
  }
}
