.cs-link {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 20px;
    color: #989898;
    font-size: 14px;

    .icon {
      width: 18px;
      height: 18px;
    }
  }

  a.active {
    color: #0ea5ef;
  }

  .caret {
    color: #989898;
  }

  &.sublink {
    display: none;

    a {
      span {
        width: 18px;
        height: 18px;
      }
    }

    &.active {
      display: block;
    }
  }
}
