.cs-button {
  width: auto;

  button {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 5px;
    text-wrap: nowrap;

    cursor: pointer;
    border-radius: 5px;
    padding: 10px 15px;
    border: 1px solid #d3d8dd;
    transition: box-shadow 0.1s;

    &.normal {
      color: #333;
      background-color: #fff;
    }

    &.success {
      color: #fff;
      border-color: #35ae57;
      background-color: #35ae57;
    }

    &.danger {
      color: #fff;
      border-color: #f44336;
      background-color: #f44336;
    }

    &:hover {
      box-shadow: 0px 1px 3px #bdbdbd;
    }
  }
}
