.member-loans {
  margin: 0 auto;
  max-width: 800px;
}

.member-loans__section {
  border-radius: 10px;
  border: 1px solid #d3d8dd;
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;

  &.section-1 {
    .member-loans__title {
      font-size: 16px;
    }

    .member-loans__info {
      display: flex;
      justify-content: center;
      margin-top: 15px;

      .member-loans__col {
        width: 200px;

        h2 {
          font-size: 16px;
        }

        p {
          font-size: 12px;
          color: #091425;
        }
      }
    }
  }

  &.section-2 {
    .member-loans__tabs {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #c9c9c9;

      ul {
        display: flex;
        list-style: none;
        padding: 10px;
        gap: 20px;

        li {
          display: flex;
          gap: 5px;
          font-size: 16px;
          font-weight: 500;
          color: #989898;

          &.selected {
            color: #000000;
          }

          span {
            display: block;
            width: 25px;
            height: 25px;
            border-radius: 50px;
            color: #ffffff;
            background-color: #f1ac44;
          }
        }
      }

      .member-loans__tabs--refresh {
        background-color: transparent;
        border: none;
        color: #989898;
        cursor: pointer;

        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .member-loans__list {
      .list-item {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;

        .details {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          .avatar {
            border-radius: 50px;
            border: 2px solid darkgreen;
            background-color: lightseagreen;
            width: 50px;
            height: 50px;
          }

          .info {
            text-align: start;

            .name {
              font-size: 14px;
              font-weight: 500;
            }

            .time {
              color: #989898;
              font-size: 10px;
            }
          }
        }

        .assign {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.member-loans__grid {
  /** Mobile */
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 15px;
  justify-content: space-between;

  .member-loans__grid--item {
    border-radius: 10px;
    border: 1px solid #d3d8dd;
    background-color: #ffffff;
    padding: 20px;

    &.no-card {
      border: none;
      background-color: transparent;
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 20px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;

      &.highlighted {
        color: #fff;
        background-color: #3a56a9;
      }

      &.highlighted-2 {
        color: #fff;
        background-color: #005153;
      }

      p {
        font-size: 13px;
      }
    }
  }
}
