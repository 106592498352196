.pre-membership {
  margin: 0 auto;

  .codes-table {
    overflow: hidden;
    border-radius: 10px;
  }

  .step-one {
    .video-container {
      width: 500px;
      height: 300px;
      border-radius: 10px;
      margin-bottom: 10px;
      overflow: hidden;
    }
  }

  .step-two {
    .quiz-container {
      overflow: hidden;
      border-radius: 10px;
    }
  }

  .step-three {
    .guide-container {
      overflow: hidden;
      border-radius: 10px;
    }
  }
}
