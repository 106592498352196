.members {
  margin: 0 auto;

  .members__list {
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #d3d8dd;

    .table {
      overflow: hidden;
      background-color: #fff;
    }
  }
}
