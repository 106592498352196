.upload-soas {
  margin: 0 auto;
  max-width: 800px;
}

.upload-soas__grid {
  /** Mobile */
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 15px;
  justify-content: space-between;

  .upload-soas__grid--item {
    border-radius: 10px;
    border: 1px solid #d3d8dd;
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .title {
      font-weight: bold;
      font-size: 18px;
    }

    .date {
      font-weight: 300;
      font-size: 13px;
    }

    .amount {
      color: #005153;
      font-size: 30px;
      font-weight: bold;
      margin-top: 20px;
    }
  }
}

.csv-reader {
  width: 100%;
  align-items: center;
  border: 2px dashed #ccc;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 20px;

  &:hover {
    border-color: #686868;
  }

  .file {
    background: linear-gradient(to bottom, #eee, #ddd);
    border-radius: 20px;
    display: flex;
    height: 120px;
    width: 120px;
    position: relative;
    z-index: 10;
    flex-direction: column;
    justify-content: center;
  }

  .info {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }

  .size {
    border-radius: 3px;
    margin-bottom: 0.5em;
    justify-content: center;
    display: flex;
  }

  .name {
    border-radius: 3px;
    font-size: 12px;
    margin-bottom: 0.5em;
  }

  .progress-bar {
    bottom: 14px;
    position: absolute;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .remove {
    height: 23px;
    position: absolute;
    right: 6px;
    top: 6px;
    width: 23px;
  }
}
