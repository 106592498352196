.settings {
  margin: 0 auto;

  .settings__list {
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #d3d8dd;
  }

  .members {
    display: flex;
    gap: 50px;
    font-size: 14px;

    .members__grid {
      width: 250px;
    }
  }
}
