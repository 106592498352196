.download-soa {
  width: 100%;
  padding: 20px;
  background-color: white;

  * {
    letter-spacing: 0.01px;
  }

  .header {
    text-align: center;

    .logo {
      height: 100px;
    }

    .text {
      font-size: 14px;
    }
  }

  .section-1 {
    margin-top: 30px;

    .title {
      text-align: center;
      font-weight: bold;
    }

    .account-details {
      > p {
        font-weight: bold;
        margin-bottom: 5px;
        border-bottom: 1px solid black;
      }
    }

    table {
      width: 100%;
    }

    padding-bottom: 5px;
    border-bottom: 1px solid black;
  }

  .section-2 {
    margin-top: 50px;

    .title {
      font-weight: bold;
    }

    table {
      width: 100%;

      td {
        padding: 5px 0;
      }
    }
  }

  .section-3 {
    margin-top: 50px;

    table {
      width: 100%;

      td {
        padding: 5px;
      }
    }
  }
}
