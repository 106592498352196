.members-logs {
  margin: 0 auto;

  .members-logs__list {
    overflow: hidden;
    // border-radius: 10px;
    // border: 1px solid #d3d8dd;

    font-size: 14px;
  }
}
