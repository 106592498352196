/* CSS styles for the layout */

.main-layout {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.sidebar {
  /** Mobile */
  @media (max-width: 768px) {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    border: #d3d8dd solid 1px;

    &.show {
      display: block;
    }
  }
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  width: 25%;
  max-width: 320px;
  padding: 20px;

  .close-sidebar {
    /** Mobile */
    @media (max-width: 768px) {
      display: block;
      text-align: right;
    }

    display: none;
  }

  .sidebar__section {
    .sidebar__logo {
      width: 100%;
      height: 65px;
      position: relative;
      margin-bottom: 50px;

      .logo {
        width: 100%;
        height: auto;
      }
    }

    .sidebar__user-info {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 50px;

      .user-info__avatar {
        border-radius: 50px;
        border: 2px solid darkgreen;
        background-color: lightseagreen;
        width: 50px;
        height: 50px;
      }

      .user-info__name {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
          font-size: 14px;
        }

        .role {
          font-size: 14px;
          color: #989898;
        }
      }
    }

    .sidebar__navigation {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-left: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid #d3d8dd;
    }
  }

  .sidebar__logout {
    margin-left: 10px;
    padding-top: 20px;
    border-top: 1px solid #d3d8dd;

    a {
      color: #0ea5ef;
      font-size: 14px;
      text-decoration: none;
    }
  }
}

.main {
  /** Mobile */
  @media (max-width: 768px) {
    width: 100%;
  }
  
  width: 80%;
  background-color: #fafbfc;
  border-left: 1px solid #d3d8dd;
}

.navbar {
  /** Mobile */
  @media (max-width: 768px) {
    justify-content: space-between;
  }

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  min-height: 50px;
  background-color: #fafbfc;
  border-bottom: 1px solid #d3d8dd;

  .menu-button {
    /** Mobile */
    @media (max-width: 768px) {
      display: block;
    }

    display: none;
  }

  .navbar__online {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .navbar__online-counter {
      font-size: 14px;
      color: #ffffff;
      border-radius: 4px;
      border: 1px solid #35b258;
      background-color: #35b258;
      width: fit-content;
      height: 100%;
      text-align: center;
      padding: 8px 12px;

      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        color: #35b258;
        border-radius: 50px;
        background-color: #ffffff;
        font-size: 14px;
      }
    }

    .navbar__online-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #35b258;
      background-color: #ffffff;
      width: fit-content;
      height: 100%;
      text-align: center;
      padding: 8px 12px;

      span {
        display: inline-block;
        width: 10px;
        height: 10px;
        color: #35b258;
        border-radius: 50px;
        background-color: #35b258;
      }
    }

    .navbar__forum {
      background-color: #35b258;
      height: 35px;
      width: 50px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.content {
  overflow: auto;
  padding: 20px;
  height: calc(100vh - 50px);
}
